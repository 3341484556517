import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { Override, Section, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<Helmet>
			<title>
				Process - kursin.com - modern web development
			</title>
			<meta name={"description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:title"} content={"Process - kursin.com - modern web development"} />
			<meta property={"og:description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1589561253898-768105ca91a8?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
			<script async src={`https://www.googletagmanager.com/gtag/js?id=UA-176560010-1`}></script>
     		<script>
			{`
      	    window.dataLayer = window.dataLayer || [];
          	function gtag(){dataLayer.push(arguments);}
          	gtag('js', new Date());
          	gtag('config', "UA-176560010-1");
        	`}
		   	</script>
		</Helmet>
		<Components.Header />
		<Section
			sm-padding="24px 0 24px 0"
			quarkly-title="About"
			box-sizing="border-box"
			padding="50px 0px 50px 0px"
			background="--color-lightD1"
			border-color="--color-lightD2"
			border-style="solid"
			border-width="1px 0px 1px 0px"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				text-align="center"
				font="--base"
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				color="--dark"
				lg-margin="0px 0px 6px 0px"
				lg-text-align="center"
				quarkly-title="Title"
				text-transform="uppercase"
			>
				Our Work
			</Text>
			<Text
				font="normal 300 38px/1.2 --fontFamily-googleMontserrat"
				margin="0px 0px 0px 0px"
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="normal 300 38px/1.5 --fontFamily-googleMontserrat"
				sm-font="normal 300 22px/1.5 --fontFamily-googleMontserrat"
				md-font="normal 300 30px/1.5 --fontFamily-googleMontserrat"
			>
				About our developing process
			</Text>
		</Section>
		<Section padding="100px 0 80px 0" lg-padding="60px 0 60px 0" quarkly-title="Info" justify-content="center">
			<Override
				slot="SectionContent"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Stack justify-content="space-between">
				{"    "}
				<StackItem
					width="40%"
					display="flex"
					lg-width="100%"
					lg-order="2"
					lg-margin="0px 0px 0px 0px"
				>
					<Override
						slot="StackItemContent"
						flex-direction="column"
						justify-content="flex-start"
						lg-align-items="center"
						align-items="center"
						padding="0px 30px 0px 0px"
						lg-padding="0px 0px 0px 0px"
						md-align-items="center"
						xl-align-items="center"
					/>
					{"        "}
					<Text
						font="--base"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						color="--dark"
						opacity="0.6"
						lg-text-align="center"
						lg-margin="0px 0px 6px 0px"
						quarkly-title="Title"
						text-transform="uppercase"
					>
						MOBILE APP ON BUBBLE
					</Text>
					<Text
						font="normal 300 40px/1.2 --fontFamily-googleMontserrat"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
						md-font="normal 300 28px/1.2 --fontFamily-googleMontserrat"
						xl-text-align="center"
						text-align="center"
					>
						Simple Sign Up Form
					</Text>
					<Text
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="left"
						md-font="--base"
						xl-text-align="center"
						text-align="center"
					>
						Creating Sign Up Form using modern NoCode tool Bubble. Custom Sign Up Form usually better than basic one.
					</Text>
					<Link
						href="/contact"
						text-decoration-line="initial"
						color="--dark"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						text-align="center"
						margin="9px 0px 0px 0px"
						background="--color-light"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						border-width="1px"
						border-style="solid"
					>
						I Want This Too!
					</Link>
					{"    "}
				</StackItem>
				{"    "}
				<StackItem
					width="60%"
					display="flex"
					padding="0px 0px 0px 0px"
					miniPk-width="59%"
					lg-width="100%"
					lg-order="1"
					lg-margin="0px 0px 10px 0px"
					quarkly-title="Illustration"
				>
					<Override slot="StackItemContent" justify-content="center" />
					<Components.EmbedHTML
						display="flex"
						min-width="200px"
						min-height="150px"
						position="static"
						flex-wrap="nowrap"
					/>
					{"            "}
				</StackItem>
			</Stack>
			<Stack margin="80px -16px -16px -16px" justify-content="space-between" flex-direction="row" lg-margin="40px -16px -16px -16px">
				{"        "}
				<StackItem
					width="40%"
					display="flex"
					lg-margin="0px 0px 0px 0PX"
					lg-width="100%"
					lg-justify-content="center"
					lg-order="2"
				>
					<Override
						slot="StackItemContent"
						flex-direction="column"
						justify-content="flex-start"
						lg-align-items="center"
						align-items="center"
						padding="0px 30px 0px 0px"
						lg-padding="0px 0px 0px 0px"
						xl-align-items="center"
					/>
					{"        "}
					<Text
						font="--base"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						color="--dark"
						opacity="0.6"
						lg-margin="0px 0px 6px 0px"
						lg-text-align="center"
						quarkly-title="Title"
						text-transform="uppercase"
					>
						Mobile app with many workflows
					</Text>
					<Text
						font="normal 300 40px/1.2 --fontFamily-googleMontserrat"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
						md-font="normal 300 28px/1.2 --fontFamily-googleMontserrat"
						text-align="center"
					>
						Subscriptions app
					</Text>
					<Text
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						md-font="--base"
						text-align="center"
					>
						Workflows are the main part of any application. Usually there are a lot of workflows with complicated logic, better to describe every action in documentation or comments.
					</Text>
					<Link
						href="/contact"
						text-decoration-line="initial"
						color="--dark"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						text-align="center"
						margin="9px 0px 0px 0px"
						background="--color-light"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						border-width="1px"
						border-style="solid"
					>
						I Want This Too!
					</Link>
					{"    "}
				</StackItem>
				<StackItem
					width="60%"
					display="flex"
					padding="0px 0px 0px 0px"
					miniPk-width="59%"
					lg-width="100%"
					lg-margin="0px 0px 10px 0px"
					quarkly-title="Illustration"
					lg-order="1"
				>
					<Override slot="StackItemContent" lg-width="100%" />
					{"        "}
					<Box
						width="100%"
						background="--color-lightD2 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/Captura%20de%20pantalla%202020-12-02%20a%20las%2020.41.40.png?v=2020-12-02T19:42:32.618Z) center center/100% no-repeat"
						margin="0px 0px 0px 0px"
						padding="0px 0px 60% 0px"
						hover-background="--color-lightD2 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/Captura%20de%20pantalla%202020-12-02%20a%20las%2020.41.40.png?v=2020-12-02T19:42:32.618Z) center center/110% no-repeat"
						transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					/>
					{"    "}
				</StackItem>
			</Stack>
			<Stack margin="80px -16px -16px -16px" justify-content="space-between" lg-margin="40px -16px -16px -16px">
				{"    "}
				<StackItem
					width="40%"
					display="flex"
					lg-width="100%"
					lg-order="2"
					lg-margin="0px 0px 0px 0px"
					box-sizing="border-box"
				>
					<Override
						slot="StackItemContent"
						flex-direction="column"
						justify-content="flex-start"
						lg-align-items="center"
						align-items="center"
						box-sizing="border-box"
						padding="0px 30px 0px 0px"
						lg-padding="0px 0px 0px 0px"
					/>
					{"        "}
					<Text
						font="--base"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						color="--dark"
						opacity="0.6"
						lg-text-align="center"
						lg-margin="0px 0px 6px 0px"
						quarkly-title="Title"
						text-transform="uppercase"
					>
						MOBILE AND Web app
					</Text>
					<Text
						font="normal 300 40px/1.2 --fontFamily-googleMontserrat"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						md-margin="0px 0px 16px 0px"
						md-font="normal 300 28px/1.2 --fontFamily-googleMontserrat"
						text-align="center"
					>
						Database logic
					</Text>
					<Text
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						md-font="--base"
						text-align="center"
					>
						Right database structure is a very important thing for every mobile and application with user interaction. Better to start drawing database logic before starting other parts of the project.{" "}
					</Text>
					<Link
						href="/contact"
						text-decoration-line="initial"
						color="--dark"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						text-align="center"
						margin="9px 0px 0px 0px"
						background="--color-light"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						border-width="1px"
						border-style="solid"
					>
						I Want This Too!
					</Link>
					{"    "}
				</StackItem>
				{"    "}
				<StackItem
					width="60%"
					display="flex"
					padding="0px 0px 0px 0px"
					miniPk-width="59%"
					lg-width="100%"
					lg-order="1"
					lg-margin="0px 0px 10px 0px"
					quarkly-title="Illustration"
				>
					{"        "}
					<Box
						width="100%"
						background="--color-lightD2 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/Captura%20de%20pantalla%202020-12-02%20a%20las%2020.43.18.png?v=2020-12-02T19:43:54.172Z) center center/100% no-repeat"
						margin="0px 0px 0px 0px"
						padding="0px 0px 60% 0px"
						hover-background="--color-lightD2 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/Captura%20de%20pantalla%202020-12-02%20a%20las%2020.43.18.png?v=2020-12-02T19:43:54.172Z) center center/110% no-repeat"
						transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					/>
					{"    "}
				</StackItem>
			</Stack>
		</Section>
		<Components.Upfooter />
		<Components.FooterMain>
			<Override slot="stackItem" lg-width="100%" sm-width="100%" />
		</Components.FooterMain>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
	</Theme>;
});